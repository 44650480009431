import { Params } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AllowedBaseSite } from 'src/environments/kurz-env.type';


export class UtilStringHelper {

  /**
   * Secure context: This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.
   * 'SHA-1' should not be used in cryptographic applications
   */
  static async hashString(str: string, algo: 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512' = 'SHA-1', toHex = false) {
    if (!window?.crypto || !TextEncoder) {
      console.warn('string encoding is available only in secure contexts (HTTPS)');
      return str;
    } else {
      const encoder = new TextEncoder();
      const data = encoder.encode(str);
      const hashBuffer = await crypto.subtle.digest(algo, data);

      let hash: string;
      if (toHex) {
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        // eslint-disable-next-line
        hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
        return hash;
      } else {
        const decoder = new TextDecoder();
        const decoded = decoder.decode(hashBuffer);
        return decoded;
      }
    }
  }

  static hashStringSync(str: string, result: 'numbers-only' | 'base64' | 'base64-url-friendly') {

    let hash = 0;
    let hashStr = '';

    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }

    if (result === 'numbers-only') {

      hashStr = hash.toString();

    } else {

      hashStr = window.btoa(hash.toString());

      if (result === 'base64-url-friendly') {
        hashStr = hashStr.replaceAll('=', '§').replaceAll('+', '_').replaceAll('/', '!');
      }

    }

    return hashStr;
  }

  /**
   * Splits a given string into parts separated by a given character or string. Works similar to String.split() but it is possible
   * to call this function with enclosing characters. Text enclosed won't be splitted.
   * @param str - string, which will be processed
   * @param separator - given character
   * @param enclosingPairs - Array of Enclosing Pairs.
   * @example splitStringAdv('a.b.c(12.5).d', '.', [{openingChar: '(', closingChar: ')'}]) => ['a', 'b', 'c(12.5)', 'd']
   * @example
   * const str = '{"test": "Hello; World", "arr": [1, 2], "calc": "(23.5 - 5.1) * 23 + 15"}; "hello"; [];';
   * splitStringAdv(str, ';', [
   *   {openingChar: '(', closingChar: ')'}, // Enclosing Pairs for JSON
   *   {openingChar: '[', closingChar: ']'},
   *   {openingChar: '{', closingChar: '}'},
   *   {openingChar: '"', closingChar: '"'},
   * ], 3);
   * ==> ['{"test": "Hello; World", "arr": [1, 2], "calc": "(23.5 - 5.1) * 23 + 15"}', ' "hello"', ' []', '']
   */
  static splitStringAdv(str: string, separator: string, enclosingPairs: EnclosingPair[], maxElements?: number): string[] {

    const arr: string[] = [];

    let i: number;
    let lefti = 0;
    let righti = 0;
    maxElements ||= str.length;

    // can also be used as a pointer / index for the history but 1 needs to be substracted
    let currentEnclosingPairDepth = 0;
    const currentEnclosingPairHistory: EnclosingPair[] = [];

    const getCurrentEnclosingPair = () => (currentEnclosingPairDepth < 1 ? null : currentEnclosingPairHistory[currentEnclosingPairDepth - 1]);

    const isThisCharOpeningCharOfAnyEnclosingPair = (char: string): EnclosingPair | undefined => {
        return enclosingPairs.find(p => p.openingChar === char);
    };

    const isThisCharClosingCharOfCurrentEnclosingPair = (char: string): boolean => {
        return !!(getCurrentEnclosingPair() && getCurrentEnclosingPair()!.closingChar === char);
    };

    for (i = 0; i < str.length; i++) {
        const curChar = str[i];
        let alreadyFound = false;

        if (curChar === separator && !getCurrentEnclosingPair()) {
            arr.push(str.slice(lefti, righti));

            if (arr.length >= maxElements) {
              break;
            }

            lefti = righti + 1;

            alreadyFound = true;
        }

        if (!alreadyFound && isThisCharClosingCharOfCurrentEnclosingPair(curChar)) {
            currentEnclosingPairDepth--;
            currentEnclosingPairHistory.pop();
            alreadyFound = true;
        }

        if (!alreadyFound) {

            const openingEnclosingPair = isThisCharOpeningCharOfAnyEnclosingPair(curChar);

            if (openingEnclosingPair) {
                currentEnclosingPairHistory[currentEnclosingPairDepth] = openingEnclosingPair;
                currentEnclosingPairDepth++;
            }
        }


        righti++;

    }

    if (arr.length < maxElements) {
        arr.push(str.slice(lefti, righti));
    }

    return arr;

  }

  static getCookieValue(name: string): string {
    let value = '';
    value = document.cookie.split(name + '=')?.[1]?.split(';')?.[0];
    return value;
  }

}


export function stringToScreaminSnakeCase(str: string): string {
  let res = '';

  Array.from(str).forEach(char => {
    if (/[A-Za-z]{1}/.test(char)) {
      res += char.toUpperCase();
    } else {
      if (/[ _-]{1}/.test(char)) {
        res += '_';
      }
    }
  });

  return res;
}


export function getBaseSite(): string {

  let baseSite = environment.currentBaseSite;

  const getHackedBaseSiteFn = (): AllowedBaseSite => {
    const pathParams = window.location.pathname.split('/');
    const baseSite = pathParams.find(param => param.includes('kurz-')) || 'kurz-de';
    if(environment.production) {
      console.warn('determines the base site unsavely! Result:', baseSite);
    }
    return baseSite as AllowedBaseSite;
  };

  baseSite ||= getHackedBaseSiteFn();

  return baseSite;
}


export function getUrlOfEndpoint(endpoint: string, queryParams?: Params) {

  endpoint = endpoint.startsWith('/') ? endpoint : ('/' + endpoint);
  const searchQuery = (Object.keys(queryParams || {}).filter(key => queryParams[key] === 0 || !!queryParams[key]).map(key => (key + '=' + queryParams[key])).sort().join('&'));
  const url = (environment.baseUrl || '') + '/occ/v2/' + getBaseSite() + endpoint + (searchQuery ? ('?' + searchQuery) : '');
  return url;
}

/**
 * Media urls that are not beeing used with the spartacus cx-media
 * components should only have the baseUrl at the beginning.
 * @param {string}  url - Url of the media file.
 * @returns {string} The url of the working media.
 */
export function getUrlOfSingleMedia(url: string): string {
  return environment.baseUrl + url;
}

/**
 * Extracts the endpoint and optional query parameters from a given URL.
 *
 * @param {string} url - The URL to extract the endpoint and parameters from.
 * @param {boolean} [includeParams=false] - A flag indicating whether to include query parameters in the result.
 * @returns {{ endpoint: string; params?: Params }} An object containing the extracted endpoint and parameters (if `includeParams` is true).
 * The `endpoint` property represents the endpoint path, and the `params` property contains an object representing query parameters (key-value pairs).
 * If `includeParams` is false, the return value will only contain the `endpoint` property.
 * TODO: At the moment works only for occ urls. extend to a more general url if needed.
 */
export function getEndpointAndParamsFromUrl(url: string, includeParams: boolean = false): { endpoint: string; params?: Params } {
  const urlParts = url.split('?');
  const endpoint = url.split('?')[0].replace(/^https?:\/\/[^/]+\/occ\/v2\/[^/]+\/(.+)$/, '$1');

  if (!includeParams) {
    return { endpoint };
  }

  const queryParamsString = urlParts[1] || '';
  const queryParamsArray = queryParamsString.split('&');

  const params: Params = {};
  queryParamsArray.forEach(queryParam => {
    const [key, value] = queryParam.split('=');
    params[key] = value;
  });

  return { endpoint, params };
}

/**
 * Splits a given string into parts separated by a given character or string. Works similar to String.split() but it is possible
 * to call this function with enclosing characters. Text enclosed won't be splitted.
 * @param str - string, which will be processed
 * @param separator - given character
 * @param enclosings - String-array of 1 or 2 characters.
 * @example splitString('a.b.c(12.5).d', '.', ['(', ')']) => ['a', 'b', 'c(12.5)', 'd']
 */
export function splitString(str: string, separator: string, enclosings?: string[]) {

  const arr: string[] = [];

  if (enclosings && enclosings.length > 2) {
    console.warn('the number of enclosing characters must be 0, 1 or 2', str);
    return [str];
  }

  if (!enclosings) {
    return str.split(separator);
  }

  const getEnclosingChar = (situation: 'notInside' | 'inside') => {
    return situation === 'notInside' ? (enclosings?.[0] || enclosings?.[1]) : (enclosings?.[1] || enclosings?.[0]);
  };

  let situation: 'notInside' | 'inside' = 'notInside';

  let i: number;
  let lefti = 0;
  let righti = 0;

  for (i = 0; i < str.length; i++) {
    const curChar = str[i];

    if (curChar === separator && situation === 'notInside') {
      arr.push(str.slice(lefti, righti));
      lefti = righti + 1;
    }

    if (curChar === getEnclosingChar(situation)) {
      situation = situation === 'notInside' ? 'inside' : 'notInside';
    }

    righti++;
  }

  arr.push(str.slice(lefti, righti));
  return arr;
}


export interface EnclosingPair {
  openingChar: string;
  closingChar: string;
}


export function howOften(str: string, char: string): number {
  const r = new RegExp(char, 'gi');
  return str?.match(r)?.length;
}


export function getRandomString(length: number = 6): string {
  let str = '';

  while (str.length < length) {
    // eslint-disable-next-line
    str += Math.random().toString(36).slice(2, 10);
  }
  return str.slice(0, length);
}


export function getAllIndicesOf<T = string>(arr: T[] | string, value: T | string) {

  const indices: number[] = [];

  const isString = typeof arr === 'string';

  let lastIndex = 0;
  let startFromIndex = 0;
  let elementLength = 1;

  if (isString) {
    elementLength = (value as string).length;
  }

  while(lastIndex >= 0) {

    lastIndex = arr.indexOf(value as any, startFromIndex);

    if (lastIndex >= 0) {
      indices.push(lastIndex);
      startFromIndex = lastIndex + elementLength;
    }
  }

  return indices;
}
